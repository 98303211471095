.sub-box {
  background: linear-gradient(270deg, #0a0908 0%, #141414 100%);
  border-radius: 50px;
  padding: 30px 55px;
}
.sub-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 6rem;
}
.sub-img {
  height: 318px;
}

.sub-texts h2 {
  font-family: "Space Grotesk";
  font-size: 35px;
  font-weight: 700;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
  padding-bottom: 30px;
}

.sub-texts p {
  font-family: "Brinnan thin";
  font-size: 18px;
  font-weight: 300;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  padding-bottom: 30px;
}

.input-sub {
  background: linear-gradient(270deg, #1b1b1b 0% ,  #303030 100%);
  border-radius: 50px;
  color: #fff;
  width: 480px;
  height: 35px;
  margin-bottom: 20PX;
  padding: 18px;
}
.submit {
  background: linear-gradient(270deg, #6d0000 0%, #ff1616 100% );
  border-radius: 50px;
  padding: 7px 40px;
  font-family: Space Grotesk;
font-size: 18px;
font-weight: 700;
line-height: 23px;
letter-spacing: 0em;
text-align: center;
color: #fff;
}


@media (max-width:768px) {
  .sub-lottie{
    width: 358px;
    height: 100%;
    margin-top: 20px;
  }
  .sub-box{
    background: linear-gradient(180deg, #141414 0%, #0a0908  100%);
border-radius: 20px;
  }
  .sub-flex {
    padding-top:45px;
    flex-direction: column;
    gap: 0rem;
}
  .sub-texts{
    margin: auto;
    text-align: center;
  }

  .input-sub {
  
    width: 260px;
   
}

.sub-texts h2 {
  
  font-size: 24px;
  font-weight: 700;
  line-height: 25px;

}

.sub-texts p {
  font-family: "Brinnan thin";
  font-size: 14px;
}
}