.container{
    max-width: 1100px;
    margin: auto;
    margin-top: 8.5rem;
    line-height: 30px;
}
.container h2{
    margin-bottom: 20px;
}
.container h3{
    margin-top: 10px;
    margin-bottom: 20px;
}
.container h4{
    margin-top: 10px;
    margin-bottom: 20px;
}

.margin-10{
    margin-top: 11%;
}
@media (min-width:1600px) {
    .container{
        max-width: 1500px;
        margin: auto;
        margin-top: 8.5rem;
        line-height: 30px;
    }
    .container-2{
        max-width: 1500px;
          margin: auto;
          margin-top: 2.5rem;
          line-height: 30px;
      }
      
    
}