@use "../utils" as u;

.toast {
  position: fixed;
  bottom: 4%;
  left: 50%;
  background-color: black;
  padding: 0.5rem 1.4rem;
  border-radius: 0.3rem;
  border: 2px solid #ff161680;
  z-index: 1000;
  display: flex;
  align-items: center;

  transition: all 0.3s ease;
  transform: translateX(-50%) translateY(1rem);
  opacity: 0;
  pointer-events: none;

  &.active {
    opacity: 1;
    transform: translateX(-50%) translateY(0rem);
    pointer-events: visible;
  }

  & > :not(:last-child) {
    margin-right: 1.2rem;
  }

  button {
    display: flex;
    cursor: pointer;

    svg {
      font-size: 1.4rem;
    }
  }

  @include u.breakpoint(medium) {
    width: u.rem(300);
    justify-content: space-between;
    button {
      svg {
        font-size: 1rem;
      }
    }
  }
}
