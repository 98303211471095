@use "../utils" as u;

.wrapper {
  header {
    position: relative;
    & > div {
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
    }

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      width: 20%;
      height: u.rem(15);
      background: linear-gradient(90deg, rgba(255, 22, 22, 0) 0%, #FF1616 48.96%, rgba(255, 22, 22, 0) 100%);
      z-index: -20;
      transform: translateY(-50%);
    }
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      width: 20%;
      height: u.rem(15);
      background: linear-gradient(90deg, rgba(255, 22, 22, 0) 0%, #FF1616 48.96%, rgba(255, 22, 22, 0) 100%);
      transform: matrix(-1, 0, 0, 1, 0, 0) translateY(-50%);
      z-index: -20;
    }
  }

  main {
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    // gap: 8rem 6rem;

    .line {
      position: absolute;
    }

    $linePosition: 30%;

    .line:nth-child(1) {
      width: 100%;
      height: u.rem(5);
      left: 0%;
      top: 33%;

      background: linear-gradient(
        90deg,
        rgba(255, 22, 22, 0) 0%,
        #ff1616 35.73%,
        #ff1616 75.31%,
        rgba(255, 22, 22, 0) 100%
      );
      border-radius: u.rem(42);
    }
    .line:nth-child(2) {
      width: 100%;
      height: u.rem(5);
      left: 0%;
      top: 67%;

      background: linear-gradient(
        90deg,
        rgba(255, 22, 22, 0) 0%,
        #ff1616 35.73%,
        #ff1616 75.31%,
        rgba(255, 22, 22, 0) 100%
      );
      border-radius: u.rem(42);
    }
    .line:nth-child(3) {
      width: u.rem(5);
      height: 100%;
      top: 0%;
      left: 33%;
      background: linear-gradient(
        rgba(255, 22, 22, 0) 0%,
        #ff1616 35.73%,
        #ff1616 75.31%,
        rgba(255, 22, 22, 0) 100%
      );
      border-radius: u.rem(42);
    }
    .line:nth-child(4) {
      width: u.rem(5);
      height: 100%;
      top: 0%;
      left: 66.5%;
      background: linear-gradient(
        rgba(255, 22, 22, 0) 0%,
        #ff1616 35.73%,
        #ff1616 75.31%,
        rgba(255, 22, 22, 0) 100%
      );
      border-radius: u.rem(42);
    }

    @include u.breakpoint(large) {
      grid-template-columns: unset;
      display: block;

      .line {
        display: none;
      }

      & > :not(:last-child) {
        position: relative;

        &::before {
          content: "";
          position: absolute;
          width: 100%;
          height: u.rem(4);
          top: 100%;
          left: 0%;
          z-index: -20;

          background: linear-gradient(
            90deg,
            rgba(255, 22, 22, 0) 0%,
            #ff1616 35.73%,
            #ff1616 75.31%,
            rgba(255, 22, 22, 0) 100%
          );
          border-radius: u.rem(42);
        }
      }
    }
  }

  @include u.breakpoint(large) {
    margin-top: 8rem;
  }
  @include u.breakpoint(medium) {
    margin-top: 5rem;

    header {
      &::before {
        height: u.rem(6);
      }
      &::after {
        height: u.rem(6);
      }
    }
  }
}
