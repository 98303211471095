@use "../utils" as u;
@use "../globals/colors" as colors;

.navbarWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 200;
  height: 95px;
  transition: all 0.2s ease;

  &.scrolled {
    background-color: colors.$black;
  }

  .navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: u.rem(30) 0;

    .logo {
      width: u.rem(206);
      cursor: pointer;
    }

    .right {
      display: flex;
      align-items: center;
      transition: all 0.3s ease;
      z-index: 220;

      & > :not(:last-child) {
        margin-right: u.rem(50);
      }

      ul {
        display: flex;
        align-items: center;

        .navLink {
          position: relative;
          padding: 0.5rem u.rem(20) !important;

          &.isDropdown {
            padding: 0 0 !important;
          }

          .drodpownTitle {
            display: flex;
            align-items: center;
            padding: 0.5rem u.rem(20) ;
            justify-content: space-between;

            svg {
              margin-left: 0.3rem;
              font-size: 1rem;
            }
          }

          .dropdown {
            position: absolute;
            top: 100%;
            left: 0;
            background-color: black;
            border: 2px solid #ff161628;
            width: u.rem(200);
            padding: u.rem(10) 0;
            box-shadow: 0px 0px 30px rgb(0 0 0 / 25%);
            transition: 0.3s;
            border-radius: u.rem(4);
            z-index: 400 !important;
            transform: translateY(20%);
            opacity: 0;
            pointer-events: none;

            &.active {
              transform: translateY(0%);
              opacity: 1;
              pointer-events: visible;
            }
          }
        }

        .navLink.active::before {
          content: "";
          position: absolute;
          top: 100%;
          left: 0;
          width: 100%;
          height: u.rem(3);
          background-color: colors.$red;
        }
      }

      aside {
        display: flex;
        align-items: center;

        & > :not(:last-child) {
          margin-right: u.rem(40);
        }

        .btn {
          border: 3px solid colors.$red;
          border-radius: u.rem(14);
          padding: u.rem(4) u.rem(30);
          transition: all 0.2s ease;
          box-shadow: 0 0 5px colors.$red;

          &:hover {
            background-color: colors.$red;
          }
        }
      }
    }

    .hamburger {
      display: none;
      cursor: pointer;
      font-size: 1.8rem;
    }

    .closeBtn {
      display: none;
      cursor: pointer;
      font-size: 1.8rem;
    }

    @include u.breakpoint(twoxLarge) {
      padding: u.rem(22) 0;

      .logo {
        width: u.rem(180);
      }

      .right {
        & > :not(:last-child) {
          margin-right: u.rem(20);
        }

        ul {
          .navLink {
            padding: 0 u.rem(16);
          }
        }

        aside {
          & > :not(:last-child) {
            margin-right: u.rem(20);
          }

          .btn {
            border: 2px solid #ff1616;
            border-radius: u.rem(6);
            padding: u.rem(4) u.rem(22);
          }
        }
      }
    }

    @include u.breakpoint(xLarge) {
      .logo {
        width: u.rem(160);
      }

      .right {
        & > :not(:last-child) {
          margin-right: u.rem(14);
        }

        ul {
          .navLink {
            padding: 0 u.rem(12) !important;
          }
        }

        aside {
          & > :not(:last-child) {
            margin-right: u.rem(14);
          }

          .btn {
            border-radius: u.rem(6);
            padding: u.rem(4) u.rem(16);
          }
        }
      }
    }

    @include u.breakpoint(large) {
      .right {
        border-left: 2px solid rgba(255, 22, 22, 0.4);
        flex-direction: column;
        align-items: unset;
        justify-content: unset;
        position: fixed;
        top: 0;
        right: 0;
        width: u.rem(300);
        height: 100%;
        background-color: black;
        padding: 2rem;
        overflow-y: auto;

        transform: translateX(u.rem(300));

        &.show {
          transform: translateX(0);
        }

        & > :not(:last-child) {
          margin-right: u.rem(0);
          margin-bottom: u.rem(30);
        }

        ul {
          flex-direction: column;
          align-items: unset;

          .navLink {
            position: relative;
            padding: 0.4rem u.rem(20) !important;
            width: 100%;

            &.isDropdown {
              padding: 0 0 !important;
            }

            .drodpownTitle {
              display: flex;
              align-items: center;
              justify-content: space-between;
              // border: 2px solid red;
              padding: 0 u.rem(20);
              width: 100%;

              svg {
                margin-left: 0.3rem;
                font-size: 1rem;
              }
            }

            .dropdown {
              position: static;
              width: 100%;
              transform: unset;
              opacity: 1;
              pointer-events: visible;
              display: none;
              margin-top: 1rem;

              &.active {
                display: block;
              }
            }
          }

          & > :not(:last-child) {
            margin-right: u.rem(0);
            margin-bottom: u.rem(30);
          }
        }

        aside {
          flex-direction: column;
          align-items: unset;

          & > :not(:last-child) {
            margin-right: u.rem(0);
            margin-bottom: u.rem(16);
          }
        }
      }

      .hamburger {
        display: block;
      }

      .closeBtn {
        display: block;
        width: fit-content;
        margin-left: -0.5rem;
      }
    }
  }
}
