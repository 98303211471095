@use "../utils" as u;

.container-wrapper {
  max-width: 1440px;
  width: 80%;
  margin: 0 auto;
}
.container-wrapper-2 {
  max-width: 1600px;
  width: 65%;
  margin: 0 auto;
}
.container-wrapper-3 {
  max-width: 1400px;
  width: 70%;
  margin: 0 auto;

  @include u.breakpoint(large) {
    width: 85%;
  }
}

.block {
  display: block;
}
.inline-block {
  display: inline-block;
}
.w-fit-content {
  width: fit-content;
}
.w-full {
  width: 100%;
}
.flex {
  display: flex;
}
.justify-center {
  justify-content: center;
}
.flex.align-center {
  align-items: center;
}
.flex.justify-end {
  justify-content: flex-end;
}
@media (max-width: 768px) {
  .container-wrapper {
    width: 87%;
  }
}
