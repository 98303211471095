.back{
    position: absolute;
      text-align: center;
      margin: auto;
      left: 0;
      right: 0;
      height: 80vh;
      top: 209px;
  }
.container-2{
  max-width: 1100px;
    margin: auto;
    margin-top: 2.5rem;
    line-height: 30px;
}

.sub-text img{
    position: absolute;
    margin: auto;
    margin-left: -23px;
    top: 25px;
}
.modal-boxs{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  
    gap: 1rem;
    align-items: center;
    justify-content: center;
    text-align: center;

}
.close-box{
    position:absolute ;
    left: 47%;
    top: 84%;

}
.card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    width: 88%;
    flex-direction: column;
    color: #fff !important;
    min-width: 0;
    word-wrap: break-word;
    background: rgba(255, 255, 255, 0.27);
    background-clip: border-box;
    border: 1px solid rgb(255 0 0);
    border-radius: 20px;
    backdrop-filter: blur(2.1875rem);
    box-shadow: 0 0 5px #ff1616;
    margin-bottom: 5%;
}

.p-3 {
    padding: 1rem!important;
}

.my-card {
    position: absolute;
    left: 38%;
    top: -40px;
    border-radius: 50%;
    width: auto;
}
.text-info {
    color: #fff !important;
}
.my-card img {
    height: 70px;
    width: 70px;
    max-width: 100%;
}
.mt-3 {
    margin-top: 3rem!important;
}
.card-title {
    margin-bottom: 0.75rem;
}
.card-text:last-child {
    margin-bottom: 0;
}

.card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}
.card-footer {
    padding: 1.2rem 1.25rem;
    background-color: transparent !important;
    
}
.card_btn {
    border: 2px solid #ff1616;
    border-radius: 0.375rem;
    padding: 0.25rem 1.375rem;
    background: #FF0000;
    box-shadow: 0 0 5px #ff0000;
    color: #fff;
    cursor: pointer;
}

.sub-text{
    display: flex;
    flex-direction: column;
    right: 58px;
    top: 39px;
    line-height: 21px;
    position: absolute;
}
/*Modal css*/
.icon {
    display: inline-block;
  }
  
  .ReactModal__Overlay{
  
    backdrop-filter: blur(0.4rem) grayscale(60%);
    background: transparent!important;
}
.ReactModal__Content{
    background: transparent !important;
    border: none !important;
    padding: 0px !important;
}

  .modal-content {
    position: relative;
    backdrop-filter: blur(2.1875rem);
    background: rgb(132 132 132 / 90%);
   
    margin: auto;
    width: 72%;
    max-width: 1200px;
    color: #fff;
    padding: 30px;
    border-radius: 25px;
    left: 0;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .mySlides {
    text-align: center;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}
.mySlides img {
    width: 99%;
    height: 100%;

    
background: #FFFFFF;
border: 5px solid #FF0000;
box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.19);
border-radius: 20px;
}
.side_box {
    border: 5px solid #FF0000;
filter: drop-shadow(0px 4px 18px rgba(0, 0, 0, 0.19));
border-radius: 25px;
    padding: 11px;
    height: 100%;
}
.side_box h3 {
    font-family: 'ABeeZee';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 47px;
    text-decoration-line: underline;
    
    color: #FFFFFF;
    padding-bottom: 7%;
}
.po {
    text-align: start !important;
    line-height: 30px;
}
.copy-box {
    border: 5px solid #FF0000;
    filter: drop-shadow(0px 4px 18px rgba(0, 0, 0, 0.19));
    border-radius: 25px;
    padding: 3px 20px 0px 13px;
    margin-top: 30px;
}
.addressLine {
    display: flex;
    width: 100%;
    align-items: center;
    margin-top: 21px;
   
    gap: 51%;
}
.caption {
    color: #FFCC4D;
    font-size: 18px;
    margin-bottom: 0px !important;
}
.bttmm {
    background: transparent;
    border: none;
    text-align: center;
    align-items: center;
    padding: 0 0 7px 5px;
    font-size: 18px;
    color: #fff;
    text-decoration: underline;
    cursor: pointer;
}
.close{
    background: #ffffff9c;
border: 3px solid #FF0000;
border-radius: 10px;
font-weight: 400;
font-size: 25px;
line-height: 30px;
padding: 1px 28px 1px 28px;
color: #FF1616;
cursor: pointer;
}
.row-1 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.col-md-4,.col-md-8{
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;

}
.text-it{
    font-style: italic;
}
/* media queries*/

@media (max-width: 950px){
    .modal-boxs{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    margin-bottom: 5%;
    padding: 10%;}

    .news-box{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        margin-bottom: 5%;
        padding: 5%
    }
}

@media (max-width: 750px){
  

    .news-box{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        margin-bottom: 5%;
        padding: 5%
    }
}
@media (max-width: 576px){

    .ReactModal__Content{
        inset: 20px !important;
        padding-top: 5% !important;
        padding-bottom: 2% !important;
    }

    .modal-content{
        width: 88%;
    }
    .side_box h3 {
       
        font-size: 21px;
        line-height: 29px}
    .po {
            
            line-height: 22px;
            font-size: 12px;
        }
 .mySlides img {
            width: 68%;
            height: 91%;}

    .addressLine {
        gap: 0.1rem;
       
        padding-top: 5%;
       
        padding-left: 1%;
        padding-right: 1%;
        flex-direction: column;
    }
    .close-box {
        position: absolute;
        left: 32%;
        top: 94%;
    }
    .ReactModal__Overlay {
        -webkit-backdrop-filter: blur(.4rem) grayscale(100%);
        backdrop-filter: blur(0.4rem) grayscale(100%);}
    .caption {
        font-size: 10px;
    }
    .bttmm {
        font-size: 14px !important;
    }
    .modal-boxs{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    margin-bottom: 5%;
    padding: 10%;}

    .news-box{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        margin-bottom: 5%;
        padding: 5%
    }
    .img-b{
        height: auto !important;
    }
    .news-text{
        padding: 4% !important;
    }
        .back{
           display: none;
          }
          .card{
            margin-bottom: 11%;
            width: auto;
          }
}
@media (min-width: 576px){
.ml-sm-1, .mx-sm-1 {
    margin-left: 0.25rem!important;
}

}

@media (min-width: 768px){
.col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}

.col-md-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
}
}