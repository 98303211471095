.why-head h1 {
  margin-top: 30px;
  font-family: Space Grotesk;
  font-size: 75px;
  font-weight: 700;
  line-height: 76px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 40px;
}

.why-head p {
  margin-top: 30px;
  font-family: "Brinnan reg";
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
}

.gradient-box {
  width: 100%;
  margin: auto;
  max-width: 100%;
  position: relative;
  padding: 2% 4em;
  box-sizing: border-box;
  color: #fff;
  background: linear-gradient(180deg, #0e0e0e 0%, #000000 100%);

  background-clip: padding-box;
  /* !importanté */
  border: solid 3px transparent;
  /* !importanté */
  border-radius: 1.8em;
}
.gradient-box:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -3px;
  /* !importanté */
  border-radius: inherit;
  /* !importanté */
  background: linear-gradient(180deg, #ff1616 0%, #6d0000 100%);
}
.ab-head {
  margin-bottom: 55px;
  margin-top: 30px;
}
.d-about {
  padding-right: 6em;
}
.ab-head h1 {
  font-family: "Space Grotesk", sans-serif;
  font-size: 50px;
  font-weight: 700;
  line-height: 51px;
  letter-spacing: 0em;
  text-align: center;
}

.auit-box {
  display: grid;
  grid-template-columns: repeat(2, 211px);
  gap: 2rem;
}

.a-box {
  display: flex;
  gap: 1.5rem;
}
.a-box img {
  width: auto;
  height: 85px;
}
.detail-box h1 {
  font-family: "Space Grotesk", sans-serif;
  font-size: 38px;
  font-weight: 700;
  line-height: 51px;
  letter-spacing: 0em;
  text-align: left;
}
.detail-box p {
  font-family: "Brinnan thin";
  font-size: 15px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  padding-top: 4px;
  padding-bottom: 10px;
}
.d-about p {
  font-family: "Brinnan thin";
  font-size: 17px;
  font-weight: 300;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  padding-bottom: 1rem;
}

.reach {
  font-family: "Brinnan thin";
  text-decoration: underline;

  padding-top: 27px;
  font-size: 20px;
  font-style: italic;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: end;
}
.grid-about {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 300px);
  position: relative;
  column-gap: 3rem;
  row-gap: 3rem;
  margin-top: 6rem;
  max-width: 1100px;
  margin: auto;
  padding-top: 6rem;
}
.box-mobo {
  background: #000000;
  box-shadow: 0px 4px 35px rgba(255, 22, 22, 0.53);
  margin-top: 2rem;
  padding: 1.5rem 0rem;
  border-radius: 10px;
}

.center-logo {
  position: absolute;
  top: 56%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 180px;
  z-index: 10;
}

.content {
  position: relative;
  z-index: 1;
  padding: 1rem 4rem;
  margin: 10%;
  right: 14px;
}

.content-2 {
  position: absolute;
  top: 98px;

  padding: 1rem 9rem;
}
.content p {
  font-family: "Space Grotesk", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  padding-bottom: 8px;
}
.content h1 {
  font-family: "Space Grotesk", sans-serif;
  font-size: 42px;
  font-weight: 700;
  line-height: 51px;
  letter-spacing: 0em;
  text-align: center;
}
.content h3 {
  font-family: "Space Grotesk", sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 51px;
  letter-spacing: 0em;
  text-align: center;
}
.content-2 p {
  font-family: "Space Grotesk", sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  padding-bottom: 5px;
}
.content-2 h1 {
  font-family: "Space Grotesk", sans-serif;
  font-size: 50px;
  font-weight: 700;
  line-height: 51px;
  letter-spacing: 0em;
  text-align: center;
}
.boxes {
  position: relative;
  text-align: center;
  color: white;
}

.cutted-box-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.cutted-box-wrapper-box {
  transition: all 0.2s ease;
  z-index: -1;
  filter: drop-shadow(0px 0px 58px rgba(255, 22, 22, 0.27));
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.cutted-box {
  all: unset;
  position: absolute;

  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -2;
}
.cutted-box-red {
  all: unset;
  position: absolute;
  top: 50%;
  left: 50%;
  /* width: 100%;
  height: 100%; */
  z-index: -4;
  transform: translate(-50%, -50%) scale(1.016);
  width: 100%;
  height: 100%;
  transition: all 0.2s ease;
  opacity: 0;
}
.cutted-box-gradient {
  all: unset;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: all 0.2s ease;
  opacity: 0;
}

.boxes:hover .cutted-box-wrapper-box {
  transform: scale(1.1);
  /* transform-origin: bottom right; */
  filter: drop-shadow(0px 0px 72px rgba(255, 22, 22, 0.51));
}
.boxes:hover .cutted-box-red {
  opacity: 1;
}
.boxes:hover .cutted-box-gradient {
  opacity: 1;
}

.con-p {
  font-family: "Brinnan thin" !important;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0em;
  padding-bottom: 1.5rem !important;
  text-align: center;
}

.btn-about {
  border: 1px solid #ff1616;
  padding: 3px 40px;
  border-radius: 10px;
  margin: auto;
  color: #fff;
  display: flex;
  align-items: center;
}
.btn-about:hover {
  background: linear-gradient(180deg, #ff1616 0%, #9a0000 100%);
  box-shadow: 0px 0px 44px rgba(255, 22, 22, 0.32);
}
.side-grid {
  position: absolute;
  margin-top: 46px;
  width: 35px;
  height: 411px;
  left: 169px;
  /* top: 34%; */
  background: #ff1616;
  filter: blur(94px);
}

.side-grid-right {
  position: absolute;
  margin-top: 46px;
  width: 35px;
  height: 411px;
  right: 169px;
  /* top: 34%; */
  background: #ff1616;
  filter: blur(94px);
}
.desk-btn {
  display: block;
}
.mob-btn {
  display: none;
}
.flex-about {
  display: flex;
}
.mobo-info {
  display: none;
}

@media (max-width: 768px) {
  .why-head h1 {
    margin-top: 30px;
    font-family: Space Grotesk;
    font-size: 40px;
  }
  .why-head p {
    margin-top: 30px;
    font-family: "Brinnan reg";
    font-size: 16px;
  }
  .content {
    position: relative;
    z-index: 1;
    padding: 1rem 1rem;
  }
  .content h1 {
    font-family: "Space Grotesk", sans-serif;
    font-size: 32px;
  }
  .content p {
    line-height: 23px;
  }
  .grid-about {
    display: none;
  }
  .mobo-info {
    display: block;
  }
  .desk-btn {
    display: none;
  }
  .mob-btn {
    display: block;
  }

  .ab-head h1 {
    font-family: "Space Grotesk", sans-serif;
    font-size: 30px;
    font-weight: 700;
    line-height: 31px;
  }
  .a-box {
    display: flex;
    gap: 0.7rem;
  }
  .flex-about {
    display: flex;
    flex-direction: column-reverse;
  }
  .a-box img {
    width: auto;
    height: 55px;
  }
  .detail-box h1 {
    font-family: "Space Grotesk", sans-serif;
    font-size: 22px;
    font-weight: 700;
    line-height: 27px;
  }
  .gradient-box {
    width: 100%;
    margin: auto;
    max-width: 100%;
    position: relative;
    padding: 2% 1.3em;
  }
  .d-about {
    padding-right: 0em;
    padding-top: 20px;
  }

  .auit-box {
    display: grid;
    grid-template-columns: repeat(2, 142px);
    gap: 2rem;
  }
  .detail-box p {
    font-family: "Brinnan thin";
    font-size: 12px;
  }

  .d-about p {
    font-family: "Brinnan thin";
    font-size: 15px;
    font-weight: 300;
  }

  .reach {
    font-family: "Brinnan thin";
    text-decoration: underline;
    padding-top: 27px;
    font-size: 16px;
  }
  .side-grid-right {
    display: none;
  }
}
