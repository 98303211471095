.row {
  display: flex;
  align-items: center;
  text-align: center;
  margin: auto;

  gap: 2rem;
}

.box-container {
  max-width: 1100px;
  width: 100%;
  height: 100%;
  padding: 1%;
  margin: auto;
  border-radius: 25px;
  background: rgba(255, 255, 255, 0.27);
  margin-top: 8.4%;
  justify-content: center;
}

.box-img img {
  height: 88px;
  border-radius: 50%;
  border: 2px solid #ff1616;
}
.box-img a {
  color: #fff;
  font-size: 13px;
}

.col-md {
  margin-left: 7px;
  margin-right: 12%;
}

.col-md h1 {
  font-size: 30px;
  font-weight: 400;
}
.cooki {
  color: red;
  position: absolute;
  right: 0;
  left: 79%;
  max-width: 100px;
}
@media screen and (min-width: 1500px) {
  .box-container {
    max-width: 1650px;
    width: 80%;
  }
}
@media (max-width: 768px) {
  .work-font {
    font-size: 1rem;
  }
}

@media screen and (max-width: 600px) {
  .row {
    flex-direction: column;
  }

  .box-container {
    margin-top: 20%;
    width: 85%;
  }
  .cooki {
    color: red;
    position: absolute;
    right: 0;

    left: 49%;
    top: 77%;
  }

  .col-md h1 {
    font-size: 16px;
  }
  .box-img img {
    width: 80px;
    height: 80px;
  }
}
@media (max-width: 500px) {
  .work-font {
    font-size: 0.9rem !important;
  }
  .mbl-size {
    height: 4rem;
  }
}
