@forward "globals";

.heroSwiper {
  width: 100%;
}

.splideSlider {
  .splide__slide {
    transition: all 0.2s ease;
    opacity: 0.2;

    &.is-active {
      opacity: 1;
    }
  }
}
