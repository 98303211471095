.price-head {
  text-align: center;
}
.price-head h1 {
  font-style: normal;
  font-weight: 800;
  font-size: 64px;
  line-height: 50px;

  color: #ffffff;
}

.price-head h4 {
  font-style: normal;
  font-weight: 300;
  font-size: 40px;
  line-height: 70px;

  color: #ffffff;
}
.margin-7 {
  margin-top: 9%;
}
.price-box {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  max-width: 1000px;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: auto;
  padding-top: 8%;
}

.Price-1 {
  background: rgba(255, 255, 255, 0.27);
  backdrop-filter: blur(4px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 30px;
  border: 1px solid rgba(134, 134, 134, 0.014);
  height: 100%;
}
.Price-2 {
  background: rgba(255, 0, 0, 0.75);
  backdrop-filter: blur(4px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 30px;
  border: 1px solid rgba(134, 134, 134, 0.014);
  height: 117%;
}
.price-box h2 {
  font-family: "Kanit";
  font-style: normal;
  font-weight: 600;
  font-size: 54px;
  line-height: 73px;
  text-align: center;
  color: #ffffff;
}
.price-box h2 ::before {
  content: "";
  position: absolute;
  top: -11%;
  left: 45%;
  width: 1.3%;
  height: 228px;
  background: linear-gradient(
    180deg,
    rgba(255, 22, 22, 0) 0%,
    #ff1616 50.31%,
    rgba(255, 22, 22, 0) 100%
  );
  z-index: -20;
  transform: rotate(90deg);
}
.price-box h4 {
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  line-height: 133px;
}

.price-box span {
  padding: 2%;
  margin-bottom: 2%;
}

.price-btn {
  background: #ff1616;
  border: 4px solid #ffffff;
  backdrop-filter: blur(11px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 50px;
  cursor: pointer;
  font-weight: 600;
  font-size: 18px;
  line-height: 75px;

  align-items: center;

  color: #ffffff;
  padding: 1% 7% 1% 7%;
}
.price-btn-box {
  padding-top: 7%;
}

.price-sub {
  max-width: 900px;
  margin: auto;
  padding-top: 10%;
}
.sub-h {
  text-align: center;
  padding-bottom: 3%;
}
.sub-h h1::before {
  content: "";
  position: absolute;
  left: auto;
  margin-top: -1%;
  width: 41%;
  height: 4px;
  /* background: linear-gradient(90deg, #ff1616 0%, rgba(92, 21, 19, 0) 100%); */
  background: linear-gradient(
    174deg,
    rgba(255, 22, 22, 0) 0%,
    #ff1616 50.31%,
    rgba(255, 22, 22, 0) 100%
  );
  z-index: -20;

  transform: rotate(180deg);
}
.sub-h h1::after {
  content: "";
  position: absolute;
  left: 30%;
  margin-top: 4%;
  width: 41%;
  height: 4px;
  /* background: linear-gradient(90deg, #ff1616 0%, rgba(92, 21, 19, 0) 100%); */
  background: linear-gradient(
    174deg,
    rgba(255, 22, 22, 0) 0%,
    #ff1616 50.31%,
    rgba(255, 22, 22, 0) 100%
  );
  z-index: -20;

  transform: rotate(180deg);
}

.sub-h h1 {
  font-size: 45px;
  line-height: 42px;
}
.sub-p {
  padding-top: 2%;
  padding-left: 1%;
  padding-right: 1%;
}
.sub-p p {
  font-size: 19px;
  line-height: 41px;
  margin-bottom: 2%;
  margin-top: 1%;
}
.p2-h {
  position: relative;
  top: 46px;
}
.sub-p-ul {
  margin-left: 10%;
}
.sub-p-p ul li {
  margin-left: 5%;
}
ol li {
  text-decoration: none;
  list-style: none;
}
.img-down {
  text-align: center;
  margin: auto;
  padding-top: 5%;
}
.img-down img {
  width: 85%;
}
@media (max-width: 1500px) {
  /* .sub-h::before{
        content: "";
    position: absolute;
    top: 106%;
    left: 5%;
    width: 88%;
    background-repeat: no-repeat;
    height: 100px;
    background-image: url(https://ik.imagekit.io/l4k8brcjb/BlockAudit/Rectangle_39.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1661197773785);
    /* transform: rotate(90deg); 
    z-index: -20;
    } */
  .price-box h2 ::before {
    content: "";
    position: absolute;
    top: -11%;
    left: 45%;
    width: 1.3%;
    height: 228px;
    background: linear-gradient(
      180deg,
      rgba(255, 22, 22, 0) 0%,
      #ff1616 50.31%,
      rgba(255, 22, 22, 0) 100%
    );
    z-index: -20;
    transform: rotate(90deg);
  }
}
@media (max-width: 1320px) {
  .price-box h2 ::before {
    content: "";
    position: absolute;
    top: -11%;
    left: 45%;
    width: 1.3%;
    height: 228px;
    background: linear-gradient(
      180deg,
      rgba(255, 22, 22, 0) 0%,
      #ff1616 50.31%,
      rgba(255, 22, 22, 0) 100%
    );
    z-index: -20;
    transform: rotate(90deg);
  }
  .cooki {
    color: red;
    left: 83%;
    top: 65%;
    max-width: 100px;
    position: absolute;
    right: 0;
  }
}
@media (max-width: 875px) {
  .cooki {
    color: red;
    left: 78%;
    top: 65%;
    max-width: 100px;
    position: absolute;
    right: 0;
  }
}
@media (max-width: 768px) {
  .cooki {
    color: red;
    left: 58%;
    top: 76%;
    max-width: 100px;
    position: absolute;
    right: 0;
  }
  .price-box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 5%;
    padding: 5%;
  }
  /* .sub-h::before{
        content: "";
    position: absolute;
    top: 139%;
    left: 5%;
    width: 88%;
    background-repeat: no-repeat;
    height: 100px;
    background-image: url(https://ik.imagekit.io/l4k8brcjb/BlockAudit/Rectangle_39.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1661197773785);
    /* transform: rotate(90deg); 
    z-index: -20;
    } */
}
@media (max-width: 576px) {
  .price-head h1 {
    font-style: normal;
    font-weight: 800;
    font-size: 55px;
    line-height: 50px;

    color: #ffffff;
  }
  .sub-p {
    padding-top: 2%;
    padding-left: 8%;
    padding-right: 1%;
  }
  .Price-1 {
    margin: auto;
    width: 72%;
    height: 100%;
  }
  .p2-h {
    position: relative;
    top: 0px;
  }

  .margin-7 {
    margin-top: 30%;
  }
  .Price-2 {
    margin: auto;
    width: 90%;
    height: 105%;
    padding-top: 7px;
  }
  .price-box {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin-bottom: 5%;
    padding: 5%;
  }
  .sub-h h1::before {
    content: "";
    position: absolute;
    left: auto;
    margin-top: -3%;
    width: 83%;
    height: 4px;
    /* background: linear-gradient(90deg, #ff1616 0%, rgba(92, 21, 19, 0) 100%); */
    background: linear-gradient(
      174deg,
      rgba(255, 22, 22, 0) 0%,
      #ff1616 50.31%,
      rgba(255, 22, 22, 0) 100%
    );
    z-index: -20;

    transform: rotate(180deg);
  }
  .sub-h h1::after {
    content: "";
    position: absolute;
    left: 9%;
    margin-top: 13%;
    width: 83%;
    height: 4px;
    /* background: linear-gradient(90deg, #ff1616 0%, rgba(92, 21, 19, 0) 100%); */
    background: linear-gradient(
      174deg,
      rgba(255, 22, 22, 0) 0%,
      #ff1616 50.31%,
      rgba(255, 22, 22, 0) 100%
    );
    z-index: -20;

    transform: rotate(180deg);
  }
}
