.black-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 201 !important;
  transition: all 0.4s ease;
  opacity: 0;
  pointer-events: none;
}
.black-screen.show {
  opacity: 1;
  pointer-events: visible;
}
