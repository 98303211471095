@use "../utils" as u;
@use "../globals/colors" as colors;

.wrapper {
  position: relative;

  .contactBuildings {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: -20;
    object-fit: 100% !important;
  }

  section {
    display: flex;
  }

  .formWrapper {
    flex: 1;
    position: relative;
    overflow: hidden;

    header {
      margin-bottom: u.rem(60);
    }

    margin-right: 5vw;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.14) 0%,
      rgba(217, 217, 217, 0.09) 100%
    );
    box-shadow: 0px 4px 100px rgba(0, 0, 0, 0.48);
    backdrop-filter: blur(u.rem(35));
    border-radius: u.rem(30);
    padding: u.rem(72) u.rem(80);
    transform: translate3d(0, 0, 0) !important;

    form {
      display: flex;
      flex-direction: column;

      & > :not(:last-child) {
        margin-bottom: u.rem(40);
      }

      input {
        padding: u.rem(14) u.rem(16);
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      }

      textarea {
        background-color: transparent;
        outline: none;
        padding: u.rem(14) u.rem(16);
        border: none;
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        resize: none;
        height: u.rem(160);
      }

      button {
        background: colors.$red;
        border: u.rem(3) solid #ffffff;
        backdrop-filter: blur(u.rem(11));
        border-radius: u.rem(14);
        padding: u.rem(10) u.rem(80);
        margin: 0 auto;
        margin-top: u.rem(60);
      }
    }
  }

  aside {
    flex: 1;
    padding-top: u.rem(72);

    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;

    .socialIcons {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      & > :not(:last-child) {
        margin-right: u.rem(16);
      }

      & svg {
        font-size: u.rem(32);
      }
    }
  }

  @include u.breakpoint(twoxLarge) {
    .formWrapper {
      flex: 0.7;
      padding: u.rem(50) u.rem(50);

      header {
        margin-bottom: u.rem(40);
      }

      form {
        & > :not(:last-child) {
          margin-bottom: u.rem(22);
        }

        input {
          padding: u.rem(10) u.rem(12);
        }

        textarea {
          padding: u.rem(10) u.rem(12);
          height: u.rem(100);
        }

        button {
          border: u.rem(3) solid #ffffff;
          border-radius: u.rem(10);
          padding: u.rem(8) u.rem(60);
          margin-top: u.rem(30);
        }
      }
    }

    aside {
      flex: 1;
      padding-top: u.rem(50);
    }
  }

  @include u.breakpoint(xLarge) {
    .formWrapper {
      min-width: u.rem(500) !important;
    }
  }

  @include u.breakpoint(large) {
    .formWrapper {
      min-width: unset !important;
      // width: 100%;
      max-width: unset;
      padding: u.rem(40) u.rem(50);
      flex: 1;
      // margin: 0 auto;

      header {
        margin-bottom: u.rem(20);
      }

      form {
        & > :not(:last-child) {
          margin-bottom: u.rem(18);
        }

        input {
          padding: u.rem(8) u.rem(12);
        }

        textarea {
          padding: u.rem(10) u.rem(12);
          height: u.rem(100);
        }

        button {
          border: u.rem(2) solid #ffffff;
          border-radius: u.rem(6);
          padding: u.rem(4) u.rem(40);
          margin-top: u.rem(20);
        }
      }
    }

    aside {
      padding-top: u.rem(40);

      .socialIcons {
        & svg {
          font-size: u.rem(26);
        }
      }
    }
  }

  @include u.breakpoint(medium) {
    section {
      flex-direction: column-reverse;
    }

    .formWrapper {
      margin-right: 0vw;
      margin-top: 3rem;
    }

    aside {
      text-align: center;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: u.rem(0);
    }
  }

  @include u.breakpoint(xsmall) {
    .formWrapper {
      padding: u.rem(30) u.rem(30);
      border-radius: 0.5rem;
      margin-top: 2rem;
    }
  }
}
