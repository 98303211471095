@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

$font-main: "Kanit", sans-serif;
$font-secondary: "Space Grotesk", sans-serif;
$font-inter: "Inter", sans-serif;

@font-face {
  font-family: "Brinnan reg";
  src: url("../../fonts/Brinnan\ Regular.otf");
}

@font-face {
  font-family: "Brinnan thin";
  src: url("../../fonts/Brinnan\ Light.otf");
}

@font-face {
  font-family: "Brinnan bold";
  src: url("../../fonts/Brinnan\ Bold.otf");
}
