.modalBackground {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.036);
    position: fixed;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  }
  
  .modalContainer {
    width: auto;
    height: auto;
    border: 2px solid rgba(255, 22, 22, 0.1568627451);
    border-radius: 12px;
    backdrop-filter: blur(2.1875rem);
    background-color: #ff161628;
    display: flex;
    flex-direction: column;
    padding: 25px;
    margin-right: 6%;
    margin-top: -0.2%;
    z-index: 2;
  }
  .titleCloseBtn {
    display: flex;
    justify-content: flex-end;
  }
  
  .titleCloseBtn button {
    background-color: transparent;
    color: #fff;
    border: none;
    font-size: 25px;
    cursor: pointer;
  }
  #cancelBtn {
    background-color: crimson;
  }    



  @import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"); /* COLORS */
.black {
  color: #0a0908;
}

.white {
  color: #fafcff;
}

.orange {
  color: #d09756;
}

.red {
  color: #ff1616;
}

.brown {
  color: #5c1513;
}

.wrapper {
  position: relative;
}
.contactBuildings {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: -20;
  object-fit: 100% !important;
}
 section {
  display: flex;
}
.btn-modal{
  display: flex;
  gap: 10px;
}
.formWrapper {
 
  position: relative;
  overflow: hidden;
  
 
 
  
  border-radius: 1.875rem;
  padding: 0.5rem 5rem;
  transform: translate3d(0, 0, 0) !important;
}
.formWrapper header {
  margin-bottom: 22px;
}
.formWrapper form {
  display: flex;
  flex-direction: column;
}
 .formWrapper form > :not(:last-child) {
  margin-bottom:12px;
}
.formWrapper form input {
  padding: 0.875rem 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.formWrapper form textarea {
  background-color: transparent;
  outline: none;
  padding: 0.875rem 1rem;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  resize: none;
  height: 5rem;
}
.formWrapper form button {
  background: #ff1616;
  border: 0.1875rem solid #ffffff;
  backdrop-filter: blur(0.6875rem);
  border-radius: 0.875rem;
  padding: 0.625rem 4rem;
  margin: 0 auto;
  margin-top: 2rem;
}
.formWrapper form a {
  background: #ff1616;
  border: 0.1875rem solid #ffffff;
  backdrop-filter: blur(0.6875rem);
  border-radius: 0.875rem;
  padding: 0.625rem 2rem;
  margin: 0 auto;
  margin-top: 2rem;
}

@media (max-width: 96.875em) {

 .formWrapper header {
    margin-bottom: 1.5rem;
  }
 .formWrapper form > :not(:last-child) {
    margin-bottom: 0.375rem;
  }
.formWrapper form input {
    padding: 0.625rem 0.75rem;
  }
 .formWrapper form textarea {
    padding: 0.625rem 0.75rem;
    height: 6.25rem;
  }
  .formWrapper form button {
    border: 0.1875rem solid #ffffff;
    border-radius: 0.625rem;
    padding: 0.5rem 3.75rem;
    margin-top: 1.875rem;
  }
  .formWrapper form a {
    border: 0.1875rem solid #ffffff;
    border-radius: 0.625rem;
    padding: 0.5rem 3.75rem;
    margin-top: 1.875rem;
  }

}
@media (max-width: 80em) {
 .formWrapper {
    min-width: 31.25rem !important;
  }
}
@media (max-width: 64em) {
 .formWrapper {
    min-width: unset !important;
    max-width: unset;
    padding: 2.5rem 3.125rem;
    flex: 1;
  }
 .formWrapper header {
    margin-bottom: 1.25rem;
  }
   .formWrapper form > :not(:last-child) {
    margin-bottom: 1.125rem;
  }
   .formWrapper form input {
    padding: 0.5rem 0.75rem;
  }
   .formWrapper form textarea {
    padding: 0.625rem 0.75rem;
    height: 6.25rem;
  }
   .formWrapper form button {
    border: 0.125rem solid #ffffff;
    border-radius: 0.375rem;
    padding: 0.25rem 2.5rem;
    margin-top: 1.25rem;
  }
  .formWrapper form a {
    border: 0.125rem solid #ffffff;
    border-radius: 0.375rem;
    padding: 0.25rem 2.5rem;
    margin-top: 1.25rem;
  }

}
@media (max-width: 47.5em) {
   section {
    flex-direction: column-reverse;
  }
   .formWrapper {
    margin-right: 0vw;
    margin-top: 3rem;
  }
   aside {
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 0rem;
  }
}
@media (max-width: 31.25em) {
   .formWrapper {
    padding: 1.875rem 1.875rem;
    border-radius: 0.5rem;
    margin-top: 2rem;
  }
}

#rcc-confirm-button{
  margin-right: 86px !important;
  border: 3px solid #ff1616 !important;
  border-radius: 0.875rem !important;
  padding: 0.25rem 1.875rem !important;
  transition: all 0.2s ease;
  box-shadow: 0 0 5px #ff1616 !important;
  background-color: transparent !important;
}

#rcc-decline-button{
  text-decoration: underline;
  color: red !important;
  background-color: transparent !important;
}