.font-size-pro {
  font-size: 1.2rem;
  color: white;
}
.font-size1-pro {
  font-size: 1rem;
  color: white;
  font-weight: 300;
  width: 18rem;
}
.width-box {
  width: 30rem !important;
  height: 10rem;
  border-radius: 0.5rem;
  padding: 1rem;
}
.black-box-pro {
  width: 7rem !important;
  height: 5rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
}
.size-img-pro {
  height: 3rem;
}
@media (max-width: 1396px) {
  .font-size-pro {
    font-size: 1.1rem;
    color: white;
  }
  .font-size1-pro {
    font-size: 0.9rem;
    color: white;
    font-weight: 300;
    width: 18rem;
  }
  .width-box {
    width: 28rem !important;
    height: 10rem;
    border-radius: 0.5rem;
    padding: 1rem;
  }
  .black-box-pro {
    width: 6rem !important;
    height: 5rem !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
  }
}
@media (max-width: 1276px) {
  .font-size-pro {
    font-size: 1.1rem;
    color: white;
  }
  .font-size1-pro {
    font-size: 0.9rem;
    color: white;
    font-weight: 300;
    width: 16rem;
  }
  .width-box {
    width: 26rem !important;
    height: 10rem;
    border-radius: 0.5rem;
    padding: 1rem;
  }
  .black-box-pro {
    width: 6rem !important;
    height: 5rem !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
  }
}
@media (max-width: 1024px) {
  .font-size-pro {
    font-size: 1rem;
    color: white;
  }
  .font-size1-pro {
    font-size: 0.8rem;
    color: white;
    font-weight: 300;
    width: 13rem;
  }
  .width-box {
    width: 21rem !important;
    height: 10rem;
    border-radius: 0.5rem;
    padding: 1rem;
  }
  .black-box-pro {
    width: 6rem !important;
    height: 5rem !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
  }
}
