/* COLORS */

$orange: #d09756;
$red: #ff1616;
$brown: #5c1513;
$black: #0a0908;
$red-gradient: linear-gradient(90deg, #ff1616 1.8%, #5c1513 103.76%);

.black {
  color: $black;
}
.white {
  color: #fafcff;
}
.orange {
  color: $orange;
}
.red {
  color: $red;
}
.brown {
  color: $brown;
}
