@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .ecosystemt-bullet {
    @apply w-8 h-3 rounded-full cursor-pointer transition-all duration-200 bg-white-100;
  }
  .ecosystemt-bullet.active {
    @apply bg-sharp-red;
  }
}

.bd-radius {
  position: relative;
  background: #ffffff00;
  /*The background extends to the outside edge of the padding. No background is drawn beneath the border.*/
  background-clip: padding-box;
  border: solid 1px #ff1616;
  border-radius: 10px;
}

.bg-grand {
  border: solid 2px #ff1616;
  border-radius: 10px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15) 0%,
    rgba(255, 255, 255, 0.08) 100%
  );
}

.form-head {
  padding: 55px 35px;
  width: 420px;
  margin-left: auto;
}
.h-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
}
.h-form input {
  width: 90%;
  height: 45px;
  margin: 10px auto;
  background: #ffffff;
  border-radius: 8px;
  padding: 20px;
}
.send-btn {
  margin: auto;
  background: linear-gradient(180deg, #ff1616 0%, #6d0000 100%);
  border-radius: 10px;
  padding: 6px 80px;
  font-family: "Space Grotesk", sans-serif;

  font-size: 18px;
  font-weight: 700;
  line-height: 23px;
}
.btn-modal {
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
}
.header-form h1 {
  padding-bottom: 10px;
  font-family: "Space Grotesk", sans-serif;
  font-weight: 700;
  font-size: 25px;
}
.header-form p {
  padding-bottom: 6px;
  font-family: "Brinnan thin";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
}
.btn-red-one {
  border-radius: 10px;
  background: linear-gradient(180deg, #ff1616 0%, #6d0000 100%);
  box-shadow: 0px 4px 24px 0px rgba(255, 22, 22, 0.3);
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #a8a8a8;
  opacity: 1; /* Firefox */
}
.font-b {
  font-family: "Brinnan reg";
}
.dapp-txt {
  margin-right: 0px !important;
}
.bg-proccess {
  border-radius: 20px;
  border: 2px solid #ff1616;
  background: linear-gradient(180deg, #1a1a1a 0%, #040404 100%);
}
.bg-proccess-one {
  border-radius: 20px;
  /* border: 2px solid #ff1616; */
  background: linear-gradient(180deg, #1a1a1a 0%, #040404 100%);
}
.work-box {
  border-radius: 20px;
  border: 3px solid #ff1616;

  background: #000;

  box-shadow: 0px 0px 48px 0px rgba(255, 22, 22, 0.59);
}
@media (max-width: 768px) {
  .form-head {
    padding: 45px 35px;
    width: 320px;

    margin: auto;
  }
}
@media (max-width: 768px) {
  .mbl-text {
    font-size: 1.1rem !important;
  }
}
@media (max-width: 500px) {
  .mbl-center {
    text-align: center;
  }
  .mbl-text {
    font-size: 1rem !important;
  }
}
