/* FONTS-WEIGHTS */
.weight-9 {
  font-weight: 900;
}
.weight-8 {
  font-weight: 800;
}
.weight-7 {
  font-weight: 700;
}
.weight-6 {
  font-weight: 600;
}
.weight-5 {
  font-weight: 500;
}
.weight-4 {
  font-weight: 400;
}
.weight-3 {
  font-weight: 300;
}
.weight-2 {
  font-weight: 200;
}
