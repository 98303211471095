@use "../utils" as u;

.card {
  position: relative;
  width: u.rem(120);
  height: u.rem(159);
  border-radius: u.rem(10);
  transform: translate3d(0, 0, 0) !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  & > :not(:last-child) {
    margin-bottom: u.rem(8);
  }


  
  .bgFilterImg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: 100% 100%;
  }

  .icon {
    height: 50%;
    width: 66%;
  }

  @include u.breakpoint(twoxLarge) {
    width: u.rem(130);
    height: u.rem(139);

    .icon {
      height: 45%;
      width: 50%;
    }
  }
  @include u.breakpoint(large) {
    width: u.rem(90);
    height: u.rem(100);
  }
}
