@use "../utils" as u;
@use "../globals/colors" as colors;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: u.$font-main;
}

:root {
  scroll-behavior: smooth;
  overflow-x: hidden !important;
}

body {
  font-size: 100% !important;
  background: colors.$black;
  overflow-x: hidden !important;
  color: white;
}

.font-secondary {
  font-family: u.$font-secondary;
}

.opacity-hover-effect:hover {
  opacity: 0.6;
}

.popup-hover-effect {
  transition: all 0.2s ease;
}
.popup-hover-effect:hover {
  transform: translateY(-16%);
}
