ul {
  list-style-type: disc;
}
li {
  list-style: disc;
  list-style-type: disc;
}

.list-style-type-none {
  list-style-type: none;
}
.list-style-type-none li {
  list-style: none;
  list-style-type: none;
}

.list-style-type-decimal {
  list-style-type: decimal;
}
.list-style-type-decimal li {
  list-style-type: decimal;
  list-style: decimal;
}

.list-style-position-inside {
  list-style-position: inside;
}
