.text-indent-5 {
  text-indent: 5%;
}
.text-indent-10 {
  text-indent: 10%;
}
.text-indent-15 {
  text-indent: 15%;
}
.text-indent-20 {
  text-indent: 20%;
}
.text-indent-25 {
  text-indent: 25%;
}
.text-indent-30 {
  text-indent: 30%;
}
.text-indent-35 {
  text-indent: 35%;
}
.text-indent-40 {
  text-indent: 40%;
}
.text-indent-45 {
  text-indent: 45%;
}
.text-indent-50 {
  text-indent: 50%;
}
