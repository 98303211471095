@use "../utils" as *;

/* margins */
.mb-5px {
  margin-bottom: rem(5);
}
.mb-10px {
  margin-bottom: rem(10);
}
.mb-15px {
  margin-bottom: rem(15);
}
.mb-20px {
  margin-bottom: rem(20);
}
.mb-25px {
  margin-bottom: rem(25);
}
.mb-30px {
  margin-bottom: rem(30);
}
.mb-35px {
  margin-bottom: rem(35);
}
.mb-40px {
  margin-bottom: rem(40);
}
.mb-45px {
  margin-bottom: rem(45);
}
.mb-50px {
  margin-bottom: rem(50);
}
.mb-55px {
  margin-bottom: rem(55);
}
.mb-60px {
  margin-bottom: rem(60);
}
.mb-70px {
  margin-bottom: rem(70);
}
.mb-75px {
  margin-bottom: rem(75);
}
.mb-80px {
  margin-bottom: rem(80);
}
.mb-100px {
  margin-bottom: rem(100);
}
.mb-110px {
  margin-bottom: rem(110);
}
.mb-120px {
  margin-bottom: rem(120);
}
.mb-130px {
  margin-bottom: rem(130);
}
.mb-140px {
  margin-bottom: rem(140);
}
.mb-150px {
  margin-bottom: rem(150);
}
.mb-160px {
  margin-bottom: rem(160);
}
.mb-170px {
  margin-bottom: rem(170);
}
.mb-180px {
  margin-bottom: rem(180);
}
.mb-190px {
  margin-bottom: rem(190);
}
.mb-200px {
  margin-bottom: rem(200);
}
.mb-250px {
  margin-bottom: rem(250);
}
.mb-300px {
  margin-bottom: rem(300);
}

.margin-center {
  margin: 0 auto;
}

.mt-88px {
  margin-top: rem(88);
}
.mt-100px {
  margin-top: rem(100);
}
.mt-110px {
  margin-top: rem(110);
}
.mt-120px {
  margin-top: rem(120);
}

@include breakpoint(large) {
  .mb-300px {
    margin-bottom: rem(200);
  }
  .mb-200px {
    margin-bottom: rem(100);
  }
  .mb-100px {
    margin-bottom: rem(60);
  }
  .mb-150px {
    margin-bottom: rem(80);
  }
  .mb-120px {
    margin-bottom: rem(80);
  }
  .mb-140px {
    margin-bottom: rem(80);
  }
  .mb-80px {
    margin-bottom: rem(50);
  }
  .mb-70px {
    margin-bottom: rem(40);
  }

  .mb-80px {
    margin-bottom: rem(40);
  }
}

@include breakpoint(small) {
  .mb-300px {
    margin-bottom: rem(120);
  }
  .mb-200px {
    margin-bottom: rem(90);
  }
  .mb-100px {
    margin-bottom: rem(60);
  }

  .mb-40px {
    margin-bottom: rem(30);
  }
  .mb-45px {
    margin-bottom: rem(35);
  }
  .mb-120px {
    margin-bottom: rem(50);
  }
  .mb-30px {
    margin-bottom: rem(20);
  }
  .mb-25px {
    margin-bottom: rem(16);
  }
}

@include breakpoint(xsmall) {
  .mb-300px {
    margin-bottom: rem(90);
  }
}
