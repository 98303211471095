@use "../utils" as u;

/* BORDER-RADIUSES */
.rounded {
  border-radius: 50%;
}
.radius-2px {
  border-radius: u.rem(2);
}
.radius-4px {
  border-radius: u.rem(4);
}
.radius-6px {
  border-radius: u.rem(6);
}
.radius-8px {
  border-radius: u.rem(8);
}
.radius-10px {
  border-radius: u.rem(10);
}
.radius-12px {
  border-radius: u.rem(12);
}
.radius-14px {
  border-radius: u.rem(14);
}
.radius-16px {
  border-radius: u.rem(16);
}
.radius-18px {
  border-radius: u.rem(18);
}
.radius-20px {
  border-radius: u.rem(20);
}
.radius-22px {
  border-radius: u.rem(22);
}
.radius-24px {
  border-radius: u.rem(24);
}
.radius-26px {
  border-radius: u.rem(26);
}
.radius-28px {
  border-radius: u.rem(28);
}
.radius-30px {
  border-radius: u.rem(30);
}
.radius-32px {
  border-radius: u.rem(32);
}
.radius-34px {
  border-radius: u.rem(34);
}
.radius-36px {
  border-radius: u.rem(36);
}
.radius-38px {
  border-radius: u.rem(38);
}
.radius-40px {
  border-radius: u.rem(40);
}
