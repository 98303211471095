.news-box {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  position: relative;
  gap: 1.3rem;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.news-card img {
  height: auto;
  width: 100%;
  border-radius: 30px;
  padding-bottom: 10px;
}
.btn-black {
  background-color: black;
  border: #ff1616 solid 1px;
  border-radius: 0.2rem;
  width: 8rem;
  padding: 0.2rem;
}
.btn-black1 {
  background-color: black;
  border: #ff1616 solid 1px;
  border-radius: 0.2rem;
  width: 6rem;
  padding: 0.2rem;
}
.size-icon {
  color: #ff1616;
  font-size: 3rem;
  /* margin-left: -2rem; */
}
.news-card {
  width: 356px;
  border: 2px solid #ff1616;
  border-radius: 40px;
  padding: 6px;
  height: 100%;
  text-align: start;
  backdrop-filter: blur(2.1875rem);
  background: rgba(34, 34, 34, 0.9);
  cursor: pointer;
}

.head-blog {
  font-family: "Space Grotesk", sans-serif;
  font-size: 45px;
  font-weight: 800;
  text-align: center;
  margin-bottom: 4rem;
  text-transform: uppercase;
}
/* .news-card:hover{
    z-index:100;
    -webkit-animation:scale 1s linear;
    -moz-animation:scale 1s linear;
    animation:scale 1s linear;
    transform-origin:50% 50%;
    animation-fill-mode: forwards;
      
} */

.news-card {
  transition: transform 200ms;
}
.news-card::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 2s cubic-bezier(0.165, 0.84, 0.44, 1);
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.15);
  content: "";
  opacity: 0;
  z-index: -1;
}
.news-card:hover,
.news-card:focus {
  transform: scale3d(1.006, 1.1, 1);
}
.news-card:hover::after,
.news-card:focus::after {
  opacity: 1;
}

.img-b {
  width: 100%;
  height: 171.216px;
  -o-object-fit: cover;
  object-fit: cover;
  overflow: hidden;
}

.more-img {
  width: auto;
  height: 50px;
  cursor: pointer;
}

.news-text h2 {
  color: rgb(255, 255, 255);
  font-size: 15px;
  margin-bottom: 5px;
  margin-top: 10px;
  font-family: "Brinnan reg";
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  word-break: break-word;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.news-text-p {
  font-family: "Space Grotesk";
  font-size: 15px !important;
  height: 66px !important;
  line-height: 22px;
  padding: 4px;
  margin-top: calc(0.89275vw - 3.14248px) !important;
  color: rgb(255, 255, 255);
}
.news-text {
  padding: 4px;
}
.img-frame {
  height: 20rem;
  margin-left: 3.3rem !important;
  margin-top: -3rem;
  z-index: 1;
  position: relative;
}
.hovr-card:hover {
  box-shadow: 0 1px 6px rgb(255, 5, 5) !important;
  /* transition: box-shadow 0.3s ease-in-out !important; */
  /* background: #ff1616 !important; */
  /* box-shadow: #ff1616 !important; */
}
@media screen and (max-width: 1664px) {
  .img-b {
    height: calc(13.05vw - 45.936px) !important;
    width: 100%;

    -o-object-fit: cover;
    object-fit: cover;
    overflow: hidden;
  }
}

@media (max-width: 1440px) {
  .img-size {
    width: 18rem;
    height: 10rem;
  }
  .img-frame {
    height: 18rem;
    margin-left: 3.1rem !important;
    margin-top: -3rem;
    z-index: 1;
    position: relative;
  }
}

@media (max-width: 1350px) {
  .news-card {
    width: 280px;
  }
  .img-frame {
    height: 18rem;
    margin-left: 2.8rem !important;
    margin-top: -3rem;
    z-index: 1;
    position: relative;
  }
}
@media (max-width: 1200px) {
  .news-card {
    width: 236px;
  }
}
@media (max-width: 768px) {
  .news-card {
    width: 100%;
    margin-bottom: 18px;
    padding-bottom: 12px;
    margin-top: 12px;
  }
  .img-frame {
    height: 15rem;
    margin-left: 2.4rem !important;
    margin-top: -1rem;
    z-index: 1;
    position: relative;
  }
}
@media (max-width: 500px) {
  .news-card {
    width: 100%;
    margin-bottom: 18px;
    padding-bottom: 12px;
    margin-top: 12px;
  }
  .img-frame {
    height: 15rem;
    margin-left: 2.2rem !important;
    margin-top: -1rem;
    z-index: 1;
    position: relative;
  }
}
